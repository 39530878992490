// External
import React, { useEffect, useState } from 'react';

//TODO: Replace direct @mui imports with Meadow Web later (like Box)
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';

//Internal
import Stack from '@taskrabbit/meadow-web/lib/Stack';
import { menuTextToElem, type MenuItem } from '~components/layout/SiteHeader';
import IcoMoon from '@taskrabbit/meadow-web/lib/IcoMoon';
import {
  SideMenuContainer,
  BackButton,
  SideMenuItem,
  ListStyled,
} from './SideMenu.styled';
import { useRouter } from 'next/router';
import { getNextLocale, parseLocale } from '~utils/helpers';
import { LocaleSwitcher } from '~components/layout/LocaleSwitcher';

interface SideMenuProp {
  items: MenuItem[];
  onClose: () => void;
  open: boolean;
}

const SideMenu: React.FC<SideMenuProp> = ({ open, items, onClose }) => {
  const router = useRouter();
  const [previousMenuItem, setPreviousMenuItem] = useState<MenuItem>();
  const [selectedMenuItems, setSelectedMenuItems] = useState<MenuItem[]>([]);
  const isSubMenu = !!previousMenuItem;
  const locale = getNextLocale(router);
  const { country } = parseLocale(locale);

  useEffect(() => setSelectedMenuItems(items), [items]);

  const handleMenuClick = (currentMenu: MenuItem) => {
    if (currentMenu.menu) {
      setSelectedMenuItems(currentMenu.menu);
      setPreviousMenuItem(currentMenu);

      return;
    }

    router.push(currentMenu.href);
  };

  const handleGoBack = () => {
    setSelectedMenuItems(items);
    setPreviousMenuItem(undefined);
  };

  return (
    <Drawer
      open={open}
      PaperProps={{
        sx: { width: 276 },
      }}
    >
      <SideMenuContainer>
        <Stack direction="row" justifyContent="start" sx={{ height: '48px' }}>
          {previousMenuItem && (
            <BackButton onClick={handleGoBack}>
              <IcoMoon
                name="lnr-chevron-left"
                sx={{
                  fontSize: 16,
                  marginRight: 1,
                }}
              />
              {menuTextToElem(previousMenuItem.text)}
            </BackButton>
          )}
          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', right: '9px', top: '9px' }}
          >
            <IcoMoon
              name="lnr-cross2"
              sx={{
                fontSize: 16,
                fontWeight: 400,
              }}
            />
          </IconButton>
        </Stack>

        {previousMenuItem && previousMenuItem.subMenuHeader && (
          <Typography
            component="div"
            sx={{
              fontSize: 16,
              height: '24px',
              lineHeight: '24px',
              mb: '10px',
              mt: '12px',
              textAlign: 'left',
              textTransform: 'uppercase',
            }}
          >
            {previousMenuItem.subMenuHeader}
          </Typography>
        )}

        <ListStyled $isSubMenu={isSubMenu}>
          {selectedMenuItems.map((item) => (
            <SideMenuItem
              key={typeof item.text === 'string' ? item.text : item.text.id}
              menuItem={item}
              onClick={handleMenuClick}
            />
          ))}
        </ListStyled>
        {!previousMenuItem &&
          router.asPath === '/' &&
          (country === 'CA' || country === 'ES') && (
            <LocaleSwitcher country={country} />
          )}
      </SideMenuContainer>
    </Drawer>
  );
};

export default SideMenu;
