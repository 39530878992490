// External
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { getNextLocale } from '~utils/helpers';
import MeadowButtonPrimary from '@taskrabbit/meadow-web/lib/ButtonPrimary';
import IconMoon from '@taskrabbit/meadow-web/lib/IcoMoon';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import Script from 'next/script';

// Internal
import { FormattedMessage } from '../components';
import MetricsBusService, {
  ZENDESK_HELP_BUTTON_CLICKED,
} from '../services/MetricsBusService';

// Types
export interface TRzendeskWrapperProp {}

// Styles
const TRzendeskWrapperComp = styled('div')(
  ({ theme: { breakpoints, spacing } }) => ({
    bottom: '0px',
    display: 'none',
    left: '0px',
    margin: spacing(2),
    position: 'fixed',
    zIndex: '302',

    [breakpoints.up('md')]: {
      display: 'block',
    },
  })
);
const ButtonPrimary = styled(MeadowButtonPrimary)(() => ({
  alignItems: 'center',
  display: 'flex',
  fontSize: '16px',
  justifyContent: 'center',
}));

const TRzendeskWrapper = () => {
  const [scriptActive, updateScriptActive] = useState<boolean>(false);
  const [scriptLoaded, updateScriptLoaded] = useState<boolean>(false);
  const router = useRouter();
  const locale = getNextLocale(router);

  useEffect(() => {
    window.zESettings = {
      webWidget: {
        chat: {
          connectOnPageLoad: false,
          departments: {
            enabled: [],
          },
          locale,
        },
      },
    };
  }, [locale]);

  const loadZendesk = () => {
    MetricsBusService.fire(ZENDESK_HELP_BUTTON_CLICKED);

    window.zE('messenger', 'show');
    window.zE('messenger', 'open');
    window.zE('messenger:set', 'locale', locale);
    window.zE('messenger:set', 'conversationTags', [
      'script-successful',
      locale,
    ]);
    updateScriptActive(true);
  };

  return (
    <TRzendeskWrapperComp>
      {!scriptActive && scriptLoaded && (
        <ButtonPrimary onClick={loadZendesk}>
          <IconMoon
            color="inherit"
            name="question-lined"
            sx={{ fontSize: '20px', ml: -0.25, mr: 1, mt: -0.25 }}
          />
          <FormattedMessage id="web.shared.zendesk_help_widget.title" />
        </ButtonPrimary>
      )}

      <Script
        id="ze-snippet"
        onLoad={() => {
          if (window.zE) {
            window.zE('messenger', 'hide');
          }
          updateScriptLoaded(true);
        }}
        src="https://static.zdassets.com/ekr/snippet.js?key=5d5af502-eb20-48d4-96fc-1e28ad140cde"
        strategy="lazyOnload"
      />
    </TRzendeskWrapperComp>
  );
};

export default TRzendeskWrapper;
