/**
 * For now, brought entire event config over from V3:
 *
 * https://github.com/taskrabbit/v3/blob/3375b9d/packages/tr-web/apps/web/src/config/metricsEventConfig.js
 *
 * Running with assumption that we're only sending V3 "bus" events, all events
 * are now represented as simple constants with intended event name as value
 *
 * Original `eventKey` casing, order, line spacing, and comments have been preserved
 *
 * Eliminated automagical batching indirection from event arrays
 *
 * TODO: Type definitions for what `data` can be paired with events?
 *  e.g. https://github.com/taskrabbit/v3/blob/82780be/packages/tr-web/apps/web/src/marketingGroup/FireMarketingGroupShowViewedEvent.js
 */

export const DOWNLOAD_THE_APP_EVENT_NAME = 'download_the_app_clicked';

/* no custom event type */
export const COVID_MODAL_OPENED_EVENT_NAME =
  'covid-19-learn-more-modal-clicked';
export const COVID_LINK_CLICK_EVENT_NAME = 'covid-19-learn-more-link-clicked';
export const COVID_BANNER_DISMISSED_EVENT_NAME = 'covid-19-banner-dismissed';

export const RESCUE_BANNER_DISMISS_CLICK_EVENT_NAME =
  'rescue_banner_dismiss_clicked';

/* not used in v3 code, parity with v3 */
// export const RESCUE_BANNER_CLICK_EVENT_NAME = 'rescue_banner_clicked';
// export const RESCUE_RECOMMENDATION_BOOK_EVENT =
//   'rescue_recommendation_book_job_clicked';
// export const RESCUE_RECOMMENDATION_FIND_ANOTHER_TASKER_EVENT =
//   'rescue_recommendation_find_another_tasker_clicked';
// export const ACCOUNT_ADDRESS_TAB = 'account_address_tab';
// export const ACCOUNT_ADDRESS_EDIT = 'account_address_edit';
// export const ACCOUNT_ADDRESS_NEW = 'account_address_new';
// though not used, wonder how these might be used or prepared for new tasker profile updates?
// export const RECOMMENDATION_TASKER_PROFILE_REVIEWS_CATEGORY_CHANGED = 'recommendation_tasker_profile_reviews_category_changed';
// export const RECOMMENDATION_TASKER_PROFILE_CTA_CLICKED = 'recommendation_tasker_profile_cta_clicked';

export const RESCUE_BANNER_VIEWED = 'rescue_banner_viewed';
export const RESCUE_MY_TASKS_VIEWED = 'rescue_my_tasks_viewed';

export const TASKER_PREVIEW_HIRE_REPLACEMENT_TAPPED =
  'taskerPreview_hireReplacement_tapped';
export const TASKER_PREVIEW_FIND_REPLACEMENT_TAPPED =
  'taskerPreview_findReplacement_tapped';

export const TASKER_PREVIEW_SEE_PROFILE_TAPPED =
  'taskerPreview_seeProfile_tapped';
export const FUNNEL_CREATED = 'funnel_created';

export const RESCUE_BANNER_DISMISS_CLICKED = 'rescue_banner_dismiss_clicked';
export const RESCUE_BANNER_CLICKED = 'rescue_banner_clicked';

export const ON_DEMAND_REFERRAL_CTA_VISIBLE = 'On Demand Referral CTA Visible';
export const VISITOR_EXIT = 'visitor_exit';
export const ON_DEMAND_ADVOCATE_COPIED_REFERRAL_LINK =
  'on_demand_advocate_copied_referral_link';
export const ON_DEMAND_REFERRAL_SCREEN_VIEWED =
  'on_demand_referral_screen_viewed';
export const ON_DEMAND_REFERRAL_SCREEN_DISMISSED =
  'on_demand_referral_screen_dismissed';
export const ON_DEMAND_REFERRAL_SENT = 'on_demand_referral_sent';
export const ON_DEMAND_REFERRAL_MODAL_REDIRECT =
  'on_demand_referral_modal_redirect';
export const SIGNUP_INTENT_CHOSEN = 'signup_intent_chosen';
export const SIGNUP_VIEWED = 'signup_viewed';
export const SIGNUP_ERROR = 'signup_error';
export const LOGIN_VIEWED = 'login_viewed';
export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_ERROR = 'login_error';
export const LOGIN_SIGNUP_VIEWED = 'login_signup_viewed';

// chat notification popup
export const NOTIFICATION_POPUP_REQUEST = 'notification_popup_request';
export const NOTIFICATION_POPUP_ALLOWED = 'notification_popup_allowed';
export const NOTIFICATION_POPUP_BLOCKED = 'notification_popup_blocked';
export const NOTIFICATION_POPUP_CLICKED = 'notification_popup_clicked';

// Build Form Events

// default address
// booking flow events
export const BOOKING_FLOW_ADDRESS_FIELD_CLICKED =
  'booking_flow_address_field_clicked';
export const BOOKING_FLOW_DEFAULT_ADDRESS_SET =
  'booking_flow_default_address_set';
export const BOOKING_FLOW_DEFAULT_ADDRESS_EXISTS =
  'booking_flow_default_address_exists';

// Form Builder subscriptions
export const SMART_SEARCH_VIEW_ALL = 'smart_search_view_all';

export const POSTING_FORM_VIEWED = 'posting_form_viewed';
export const POSTING_FORM_COMPLETED = 'posting_form_completed';
export const IKEA_SELECT_ITEMS_VIEWED = 'ikea_select_items_viewed';
export const IKEA_SELECT_ITEMS_COMPLETED = 'ikea_select_items_completed';
export const LEAD_NURTURE_INTENT_CAPTURED = 'lead_nurture_intent_captured';
export const IKEA_JOB_SEE_TASKERS_AND_PRICES_CLICK =
  'ikea_job_see_taskers_and_prices_click';

// furniture items type events
export const FURNITURE_ITEMS_TYPE_CAPTURED = 'furniture_items_type_captured';

// ikea redirect events
export const IKEA_REDIRECT_CHOICE = 'ikea_redirect_choice';

// quote form events
export const QUOTE_FORM_VIEWED = 'quote_form_viewed';
export const QUOTE_FORM_COMPLETED = 'quote_form_completed';

// direct hire events
export const DIRECT_HIRE_FORM_VIEWED = 'direct_hire_form_viewed';
export const DIRECT_HIRE_FORM_COMPLETED = 'direct_hire_form_completed';

// repost task book events
export const REPOST_TASK_FORM_VIEWED = 'repost_task_form_viewed';

// datetime pre-select modal events
export const DATETIME_PRE_SELECT_MODAL_VIEWED =
  'datetime_pre_select_modal_viewed';
export const DATETIME_PRE_SELECT_MODAL_COMPLETED =
  'datetime_pre_select_modal_completed';
export const GM_SUGGESTED_TASKER = 'gm_suggested_tasker';

// recommendation events
export const RECOMMENDATIONS_VIEWED = 'recommendations_viewed';
export const RECOMMENDATION_CTA_CLICKED = 'recommendation_cta_clicked';
export const RECOMMENDATION_PAGE_CLICKED = 'recommendation_page_clicked';
export const RECOMMENDATION_TASKER_SCHEDULE_CTA_CLICKED =
  'recommendation_tasker_schedule_cta_clicked';
export const RECOMMENDATION_TASKER_PROFILE_REVIEWS_PAGE_CHANGED =
  'recommendation_tasker_profile_reviews_page_changed';
export const RECOMMENDATION_REVIEW_MODAL_SHOWN =
  'recommendation_review_modal_shown';
export const TASKER_SCHEDULE_VIEWED = 'tasker_schedule_viewed';
export const RECOMMENDATIONS_SCHEDULE_FILTER_CHANGED =
  'recommendations_schedule_filter_changed';
export const RECOMMENDATIONS_TIME_FILTER_CHANGED =
  'recommendations_time_filter_changed';
export const RECOMMENDATIONS_PRICE_FILTER_CHANGED =
  'recommendations_price_filter_changed';
export const RECOMMENDATIONS_VEHICLE_FILTER_CHANGED =
  'recommendations_vehicle_filter_changed';
export const RECOMMENDATIONS_SORT_CHANGED = 'recommendations_sort_changed';
export const RECOMMENDATIONS_RECURRING_FILTER_CHANGED =
  'recommendations_recurring_filter_changed';
export const RECOMMENDATIONS_BADGE_FILTER_CHANGED =
  'recommendations_badge_filter_changed';
export const RECOMMENDATIONS_JOB_SCHEDULE_CHANGED =
  'recommendations_job_schedule_changed';
export const TASKER_DETAIL_VIEWED = 'tasker_detail_viewed';
export const TASKER_PHOTO_SECTION_DISPLAYED = 'tasker_photo_section_displayed';
export const TASKER_PHOTO_VIEWED = 'tasker_photo_viewed';
export const TASKER_PHOTO_REPORTED = 'tasker_photo_reported';

// confirmation page
export const CONFIRMATION_PAGE_VIEWED_1104 = 'confirmation_page_viewed_1104';
export const CONFIRMATION_PAGE_VIEWED = 'confirmation_page_viewed';
export const CONFIRMATION_EDIT_TASK_CLICKED = 'confirmation_edit_task_clicked';
export const CONFIRM_AND_CHAT_BUTTON_CLICKED =
  'confirm_and_chat_button_clicked';
export const CONFIRMATION_SIGNUP_VIEWED = 'confirmation_signup_viewed';
export const CONFIRMATION_SIGNUP_ERROR = 'confirmation_signup_error';
export const CONFIRMATION_USER_CREATED = 'confirmation_user_created';
export const CONFIRMATION_SIGNUP_SUCCESS = 'confirmation_signup_success';
export const CONFIRMATION_LOGIN_VIEWED = 'confirmation_login_viewed';
export const CONFIRMATION_LOGIN_ERROR = 'confirmation_login_error';
export const CONFIRMATION_LOGIN_SUCCESS = 'confirmation_login_success';
export const CONFIRMATION_INTERSTITIAL_PRESENTED =
  'confirmation_interstitial_presented';
export const CONFIRM_DETAILS_SECURE_IT = 'confirm_details_secure_it';
export const CONFIRM_TIME_SELECTED = 'confirm_time_selected';
export const JOB_POSTED = 'job_posted';
export const JOB_POSTED_1107 = 'job_posted_1107';

// Dashboard Events
export const CANCEL_REPOST_ASKED = 'cancel_repost_asked';
export const CANCEL_REPOST_DECLINED = 'cancel_repost_declined';
export const CANCEL_REPOST_TASK_REPOSTED = 'cancel_repost_task_reposted';
export const DASHBOARD_VIEWED = 'dashboard_viewed';
export const DOWNLOAD_APP_MODAL_DISPLAYED = 'download_app_modal_displayed';
export const DOWNLOAD_APP_MODAL_TXT_MSG_ASKED =
  'download_app_modal_txt_msg_asked';

// repost_task subscriptions
export const REPOST_TASKER_CLICKED = 'repost_tasker_clicked';
export const CANCEL_TASK_ATTEMPTED = 'cancel_task_attempted';
export const RESCHEDULE_CHAT_WITH_TASKER_CLICKED =
  'reschedule_chat_with_tasker_clicked';
export const RESCHEDULE_CANCEL_TASK_CLICKED = 'reschedule_cancel_task_clicked';

// Feedback Metrics
export const POSTER_TOGGLED_TIP_AMOUNT = 'poster_toggled_tip_amount';
export const POSTER_LEFT_TIP_FOR_TASKER = 'poster_left_tip_for_tasker';
export const POSTER_VIEWED_TIPPING_FORM = 'poster_viewed_tipping_form';

// Tasker Profile Metrics
export const PROFILE_BUILD_CTA_CLICKED = 'profile_build_cta_clicked';
export const PROFILE_HIRE_CTA_CLICKED = 'profile_hire_cta_clicked';
export const PROFILE_REVIEWS_CATEGORY_CHANGED =
  'profile_reviews_category_changed';
export const PROFILE_REVIEWS_PAGE_CHANGED = 'profile_reviews_page_changed';

// IKEA Metrics
export const IKEA_FAQ_QUESTION_OPENED = 'ikea_faq_question_opened';

// BUS Metrics
export const IKEA_FLOW_RETURN_LINK_CLICKED = 'ikea_flow_return_link_clicked';

// landing page viewed
export const IKEA_LANDING_PAGE_VIEWED = 'ikea_landing_page_viewed';
export const IKEA_ADDRESS_MODAL_VIEWED = 'ikea_address_modal_viewed';
export const IKEA_LANDING_PAGE_OTHER_SERVICES_CLICKED =
  'ikea_landing_page_other_services_clicked';
export const IKEA_LANDING_PAGE_SPECIFIC_SERVICE_CLICKED =
  'ikea_landing_page_specific_service_clicked';
export const IKEA_LANDING_PAGE_ZIP_IN_COMPLETED =
  'ikea_landing_page_zip_in_completed';
export const IKEA_PARTNER_ELEMENT_OPTION_CLICKED =
  'ikea_partner_element_option_clicked';

// quote flow
export const SIMPLE_QUOTE_FORM_VIEWED = 'simple_quote_form_viewed';
export const SIMPLE_QUOTE_CONFIRM_VIEWED = 'simple_quote_confirm_viewed';
export const SIMPLE_QUOTE_JOB_DRAFT_POSTED = 'simple_quote_job_draft_posted';

// update cart
export const IKEA_CART_UPDATED = 'ikea_cart_updated';

// at-home simplified flow
export const SIMPLE_HIRE_FORM_VIEWED = 'simple_hire_form_viewed';
export const SIMPLE_HIRE_CONFIRM_VIEWED = 'simple_hire_confirm_viewed';
export const SIMPLE_HIRE_JOB_POSTED = 'simple_hire_job_posted';

// Friend (Referral) events
export const REFERRAL_PAGE_VIEWED = 'referral_page_viewed';

// Marketing Group events
export const MARKETING_GROUP_VIEWED = 'marketing_group_viewed';
export const MARKETING_GROUP_INDEX_VIEWED = 'marketing_group_index_viewed';
export const CHOOSE_PROJECT_EXPAND_CLICKED = 'choose_project_expand_clicked';
export const CHOOSE_YOUR_PROJECT_CLICKED = 'choose_your_project_clicked';

// Home Page events
export const HOMEPAGE_VIEWED = 'homepage_viewed';
export const SEARCH_BAR_ACTIVATED = 'search_bar_activated';
export const HEADER_NAVIGATION_CLICKED = 'header_navigation_clicked';
export const POPULAR_PROJECTS_CLICKED = 'popular_projects_clicked';
export const POPULAR_PROJECTS_EXPAND_CLICKED =
  'popular_projects_expand_clicked';
export const REVIEW_CLICKED = 'review_clicked';
export const POST_LINK_CLICKED = 'post_link_clicked';
export const GET_HELP_TODAY_CLICKED = 'get_help_today_clicked';
export const GET_INSPIRED_LINK_CLICKED = 'get_inspired_link_clicked';
export const VALUE_PROP_LINK_CLICKED = 'value_prop_link_clicked';
export const HERO_SEARCH_BUTTON_CLICKED = 'hero_search_button_clicked';
export const LOCATIONS_LINK_CLICKED = 'locations_link_clicked';
export const LOCATIONS_EXPAND_CLICKED = 'locations_expand_clicked';
export const LOCATION_LOOKUP_BUTTON_CLICKED = 'location_lookup_button_clicked';
export const BECOME_TASKER_CLICKED = 'become_tasker_clicked';
export const SIGNUP_CLICKED = 'signup_clicked';
export const FEATURED_TASKERS_NAVIGATION_CLICKED =
  'featured_taskers_navigation_clicked';
export const REVIEWS_NAVIGATION_CLICKED = 'reviews_navigation_clicked';

// SLP events
export const SLP_FORM_VIEWED = 'slp_form_viewed';

// Task templates
export const TASK_TEMPLATE_VIEWED = 'task_template_viewed';
export const TASK_TEMPLATE_SERVICE_PAGE_VIEWED =
  'task_template_service_page_viewed';

// Pre-populated Templates
export const PRE_POPULATED_TEMPLATE_ADDRESS_MODAL_VIEWED =
  'pre_populated_template_address_modal_viewed';
export const PRE_POPULATED_TEMPLATE_ADDRESS_MODAL_CLICKED =
  'pre_populated_template_address_modal_clicked';

// Geo marketing pages
export const LOCATIONS_INDEX_VIEWED = 'Locations_Index_Viewed';
export const LOCATION_PAGE_VIEWED = 'location_page_viewed';
export const LOCATION_SERVICE_PAGE_VIEWED = 'location_service_page_viewed';
export const LOCATION_PAGE_FORM_VIEWED = 'location_page_form_viewed';

// Become a tasker page
export const TASKER_SIGNUP_VIEWED = 'tasker_signup_viewed';
export const TASKER_IOS_CLICKED = 'tasker_ios_clicked';
export const TASKER_ANDROID_CLICKED = 'tasker_android_clicked';
export const TASKER_SIGNUP_SUCCESS = 'tasker_signup_success'; // If you change this name change it inside become_a_tasker.haml too
export const TASKER_METRO_SELECTED = 'tasker_metro_selected';
export const TASKER_CATEGORY_SELECTED = 'tasker_category_selected';
export const TASKER_GET_STARTED_CLICKED = 'tasker_get_started_clicked';
export const TASKER_SIGNUP_SCROLL = 'tasker_signup_scroll';

// subscribe to global metric events.
export const PAGE_REFERRED = 'page_referred';

export const RECOMMENDATIONS_TASKER_PROFILE_CLICKED =
  'recommendations_tasker_profile_clicked';

export const TASKER_PROFILE_CONTINUE_PURCHASE_FLOW_CLICKED =
  'tasker_profile_continue_purchase_flow_clicked';
export const CONFIRMATION_PAGE_CONTINUE_AND_CHAT_CLICKED =
  'confirmation_page_continue_and_chat_clicked';
export const JOB_BOOKED_AUTO_MATCH_FAILED = 'job_booked_auto_match_failed';
export const JOB_BOOKED_AUTO_MATCH_SUCCESS = 'job_booked_auto_match_sucess'; // typo here, to keep parity with v3
export const TASK_ADDRESS_CHANGED = 'task_address_changed';
export const TASKER_UNAVAILABLE_IN_THE_NEW_ADDRESS =
  'tasker_unavailable_in_the_new_address';
export const TASK_DETAILS_SAVED = 'task_details_saved';
export const ZENDESK_HELP_BUTTON_CLICKED = 'zendesk_help_button_clicked';

// Booking Flow Events

export const ERROR_YOUR_TASK_LOCATION_INPUT_ERROR =
  'Error_YourTaskLocation_InputError';

// key represents client_publish_key, value represents client_publish_type, some constants above does not have a custom client_publish_type
export const MetricsEventConfig: {
  [key: string]: string | undefined;
} = {
  //typo here, but to keep parity
  [BECOME_TASKER_CLICKED]: 'Become_Tasker_Clicked',
  [BOOKING_FLOW_ADDRESS_FIELD_CLICKED]: 'booking_flow_address_field_clicked',
  [BOOKING_FLOW_DEFAULT_ADDRESS_EXISTS]: 'booking_flow_default_address_exists',
  [BOOKING_FLOW_DEFAULT_ADDRESS_SET]: 'booking_flow_default_address_set',
  [CANCEL_REPOST_ASKED]: 'Cancellation Ask Repost Shown',
  [CANCEL_REPOST_DECLINED]: 'Cancellation Ask Repost Declined',
  [CANCEL_REPOST_TASK_REPOSTED]: 'Cancellation Ask Repost Hire Clicked',
  [CANCEL_TASK_ATTEMPTED]: 'Cancel Task Attempted',
  [CHOOSE_PROJECT_EXPAND_CLICKED]: 'Choose_Project_Expand_Clicked',
  [CHOOSE_YOUR_PROJECT_CLICKED]: 'Choose_Your_Project_Clicked',
  [CONFIRM_AND_CHAT_BUTTON_CLICKED]: 'Confirm and Chat Clicked',
  [CONFIRM_DETAILS_SECURE_IT]: 'Confirm Details Secure It',
  [CONFIRM_TIME_SELECTED]: 'Confirm Time Selected',
  [CONFIRMATION_EDIT_TASK_CLICKED]: 'Confirmation Edit Task Clicked',
  [CONFIRMATION_INTERSTITIAL_PRESENTED]: 'Confirmation Interstitial Presented',
  [CONFIRMATION_LOGIN_ERROR]: 'Confirmation Login Error',
  [CONFIRMATION_LOGIN_SUCCESS]: 'Confirmation Login Success',
  [CONFIRMATION_LOGIN_VIEWED]: 'Confirmation Login Viewed',
  [CONFIRMATION_PAGE_CONTINUE_AND_CHAT_CLICKED]:
    'confirmation_page_continue_and_chat_clicked',
  [CONFIRMATION_PAGE_VIEWED]: 'Confirmation Viewed',
  [CONFIRMATION_PAGE_VIEWED_1104]: 'Confirmation Page Legacy',
  [CONFIRMATION_SIGNUP_ERROR]: 'Confirmation Signup Error ', // keep parity trailing space might be unnecessary
  [CONFIRMATION_SIGNUP_SUCCESS]: 'Confirmation Signup Success', // If you change this name change it inside become_a_tasker.haml too
  [CONFIRMATION_SIGNUP_VIEWED]: 'Confirmation Signup Viewed',
  [CONFIRMATION_USER_CREATED]: 'Confirmation User Created',
  [DASHBOARD_VIEWED]: 'Dashboard_Viewed',
  [DATETIME_PRE_SELECT_MODAL_COMPLETED]: 'DateTime Pre-Select Modal Completed',
  [DATETIME_PRE_SELECT_MODAL_VIEWED]: 'DateTime Pre-Select Modal Viewed',
  [DIRECT_HIRE_FORM_COMPLETED]: 'Direct Hire Form Completed',
  [DIRECT_HIRE_FORM_VIEWED]: 'Direct Hire Form Viewed',
  [DOWNLOAD_APP_MODAL_DISPLAYED]: 'App Download Modal displayed',
  [DOWNLOAD_APP_MODAL_TXT_MSG_ASKED]: 'App Download Modal CTA clicked',
  [DOWNLOAD_THE_APP_EVENT_NAME]: 'Download_the_app_clicked',
  [FEATURED_TASKERS_NAVIGATION_CLICKED]: 'Featured_Taskers_Navigation_Clicked',
  [FUNNEL_CREATED]: 'funnel_created',
  [FURNITURE_ITEMS_TYPE_CAPTURED]: 'Furniture Items Type Captured',
  [GET_HELP_TODAY_CLICKED]: 'Get_Help_Today_Clicked',
  [GET_INSPIRED_LINK_CLICKED]: 'Get_Inspired_Link_Clicked',
  [GM_SUGGESTED_TASKER]: 'GM Suggested Tasker',
  [HEADER_NAVIGATION_CLICKED]: 'Header_Navigation_Clicked',
  [HERO_SEARCH_BUTTON_CLICKED]: 'Hero_Search_Button_Clicked',
  [HOMEPAGE_VIEWED]: 'Homepage_Viewed',
  [IKEA_ADDRESS_MODAL_VIEWED]: 'IKEA Address Modal',
  [IKEA_CART_UPDATED]: 'IKEA Cart Updated',
  [IKEA_FAQ_QUESTION_OPENED]: 'IKEA FAQ Question Opened',
  [IKEA_FLOW_RETURN_LINK_CLICKED]:
    'IKEA Flow Return Clicked during Fast Pivot A/B',
  [IKEA_JOB_SEE_TASKERS_AND_PRICES_CLICK]:
    'IKEA Select items redirect to reccos page.',
  [IKEA_LANDING_PAGE_OTHER_SERVICES_CLICKED]: 'See Other Services Clicked',
  [IKEA_LANDING_PAGE_SPECIFIC_SERVICE_CLICKED]: 'Specific Service Clicked',
  [IKEA_LANDING_PAGE_VIEWED]: 'IKEA Landing Page Viewed',
  [IKEA_LANDING_PAGE_ZIP_IN_COMPLETED]: 'IKEA Landing Page Zip-In Completed',
  [IKEA_PARTNER_ELEMENT_OPTION_CLICKED]: 'IKEA Partner Element Option Clicked',
  [IKEA_REDIRECT_CHOICE]: 'IKEA Redirect Choice',
  [IKEA_SELECT_ITEMS_COMPLETED]: 'IKEA Select Items Completed',
  [IKEA_SELECT_ITEMS_VIEWED]: 'IKEA Select Items Viewed',
  [JOB_BOOKED_AUTO_MATCH_FAILED]: 'job_booked_auto_match_failed',
  [JOB_BOOKED_AUTO_MATCH_SUCCESS]: 'job_booked_auto_match_success',
  [JOB_POSTED]: 'invitationSent',
  [JOB_POSTED_1107]: 'Invitation Sent 1107',
  [LEAD_NURTURE_INTENT_CAPTURED]: 'Lead Nurture Intent Captured',
  [LOCATION_LOOKUP_BUTTON_CLICKED]: 'Location Lookup Button Clicked',
  [LOCATION_PAGE_VIEWED]: 'Location_Page_Viewed',
  [LOCATION_SERVICE_PAGE_VIEWED]: 'Location_Service_Page_Viewed',
  [LOCATIONS_EXPAND_CLICKED]: 'Locations_Expand_Clicked',
  [LOCATIONS_INDEX_VIEWED]: 'Locations_Index_Viewed',
  [LOCATIONS_LINK_CLICKED]: 'Locations_Link_Clicked',
  [LOGIN_ERROR]: 'login_error',
  [LOGIN_SIGNUP_VIEWED]: 'Login Signup Viewed',
  [LOGIN_SUCCESS]: 'login_success',
  [LOGIN_VIEWED]: 'login_viewed',
  [MARKETING_GROUP_INDEX_VIEWED]: 'Marketing_Group_Index_Viewed',
  [MARKETING_GROUP_VIEWED]: 'Marketing_Group_Viewed',
  [NOTIFICATION_POPUP_ALLOWED]: 'notification_popup_allowed',
  [NOTIFICATION_POPUP_BLOCKED]: 'notification_popup_blocked',
  [NOTIFICATION_POPUP_CLICKED]: 'notification_popup_clicked',
  [NOTIFICATION_POPUP_REQUEST]: 'notification_popup_request',
  [ON_DEMAND_ADVOCATE_COPIED_REFERRAL_LINK]:
    'On Demand Referral Advocate Copied Link to Clipboard',
  [ON_DEMAND_REFERRAL_CTA_VISIBLE]: 'On Demand Referral CTA Visible',
  [ON_DEMAND_REFERRAL_MODAL_REDIRECT]: 'On Demand Referral Modal Redirect',
  [ON_DEMAND_REFERRAL_SCREEN_DISMISSED]: 'On Demand Referral Screen Dismissed',
  [ON_DEMAND_REFERRAL_SCREEN_VIEWED]: 'On Demand Referral Screen Viewed',
  [ON_DEMAND_REFERRAL_SENT]: 'On Demand Referral Sent',
  [PAGE_REFERRED]: 'page_referred',
  [POPULAR_PROJECTS_CLICKED]: 'Popular_Projects_Clicked',
  [POPULAR_PROJECTS_EXPAND_CLICKED]: 'Popular_Projects_Expand_Clicked',
  [POST_LINK_CLICKED]: 'Post_Link_Clicked',
  [POSTER_LEFT_TIP_FOR_TASKER]: 'poster_left_tip_for_tasker',
  [POSTER_TOGGLED_TIP_AMOUNT]: 'poster_toggled_tip_amount',
  [POSTER_VIEWED_TIPPING_FORM]: 'poster_viewed_tipping_form',
  [POSTING_FORM_COMPLETED]: 'Form Completed',
  [POSTING_FORM_VIEWED]: 'Form Viewed',
  [PRE_POPULATED_TEMPLATE_ADDRESS_MODAL_CLICKED]:
    'Pre Populated Template Address Modal Clicked',
  [PRE_POPULATED_TEMPLATE_ADDRESS_MODAL_VIEWED]:
    'Pre Populated Template Address Modal Viewed',
  [PROFILE_BUILD_CTA_CLICKED]: 'Profile CTA Clicked',
  [PROFILE_HIRE_CTA_CLICKED]: 'Profile Hire CTA Clicked',
  [PROFILE_REVIEWS_CATEGORY_CHANGED]: 'Profile Reviews Category Changed',
  [PROFILE_REVIEWS_PAGE_CHANGED]: 'Profile Reviews Page Changed',
  [QUOTE_FORM_COMPLETED]: 'Quote Form Completed',
  [QUOTE_FORM_VIEWED]: 'Quote Form Viewed',
  [RECOMMENDATION_CTA_CLICKED]: 'Recommendations CTA Selected',
  [RECOMMENDATION_PAGE_CLICKED]: 'Recommendation Page Clicked',
  [RECOMMENDATION_REVIEW_MODAL_SHOWN]: 'See Reviews Selected',
  [RECOMMENDATION_TASKER_PROFILE_REVIEWS_PAGE_CHANGED]:
    'Recommendations Tasker Profile Reviews Page Changed',
  [RECOMMENDATION_TASKER_SCHEDULE_CTA_CLICKED]:
    'recommendation_tasker_schedule_cta_clicked',
  [RECOMMENDATIONS_BADGE_FILTER_CHANGED]:
    'Recommendations Badge Filter Changed',
  [RECOMMENDATIONS_JOB_SCHEDULE_CHANGED]:
    'Recommendations Job Schedule Changed',
  [RECOMMENDATIONS_PRICE_FILTER_CHANGED]:
    'Recommendations Price Filter Changed',
  [RECOMMENDATIONS_RECURRING_FILTER_CHANGED]:
    'Recommendations Recurring Filter Changed',
  [RECOMMENDATIONS_SCHEDULE_FILTER_CHANGED]:
    'Recommendations Date Filter Changed',
  [RECOMMENDATIONS_SORT_CHANGED]: 'Recommendations Sort Changed',
  [RECOMMENDATIONS_TASKER_PROFILE_CLICKED]:
    'recommendations_tasker_profile_clicked',
  [RECOMMENDATIONS_TIME_FILTER_CHANGED]: 'Recommendations Time Filter Changed',
  [RECOMMENDATIONS_VEHICLE_FILTER_CHANGED]:
    'Recommendations Vehicle Filter Changed',
  [RECOMMENDATIONS_VIEWED]: 'Recommendations Viewed',
  [REFERRAL_PAGE_VIEWED]: 'Referral_Page_Viewed',
  [REPOST_TASK_FORM_VIEWED]: 'repost_task_form_viewed',
  [REPOST_TASKER_CLICKED]: 'repost_tasker_clicked',
  [RESCHEDULE_CANCEL_TASK_CLICKED]: 'Reschedule Cancel Task Clicked',
  [RESCHEDULE_CHAT_WITH_TASKER_CLICKED]: 'Reschedule Chat With Tasker',
  [RESCUE_BANNER_CLICKED]: 'rescue_banner_clicked',
  [RESCUE_BANNER_DISMISS_CLICKED]: 'rescue_banner_dismiss_clicked',
  [RESCUE_BANNER_VIEWED]: 'rescue_banner_viewed',
  [RESCUE_MY_TASKS_VIEWED]: 'rescue_my_tasks_viewed',
  [REVIEW_CLICKED]: 'Review_Clicked',
  [REVIEWS_NAVIGATION_CLICKED]: 'Reviews_Navigation_Clicked',
  [SEARCH_BAR_ACTIVATED]: 'Search_Bar_Activated',
  [SIGNUP_CLICKED]: 'Signup_Clicked',
  [SIGNUP_ERROR]: 'signup_error',
  [SIGNUP_INTENT_CHOSEN]: 'Signup_Intent_Chosen',
  [SIGNUP_VIEWED]: 'signup_viewed',
  [SIMPLE_HIRE_CONFIRM_VIEWED]: 'Simple Hire Confirm Viewed',
  [SIMPLE_HIRE_FORM_VIEWED]: 'Simple Hire Form Viewed',
  [SIMPLE_HIRE_JOB_POSTED]: 'Simple Hire Job Posted',
  [SIMPLE_QUOTE_CONFIRM_VIEWED]: 'Simple Quote Confirm Viewed',
  [SIMPLE_QUOTE_FORM_VIEWED]: 'Simple Quote Form Viewed',
  [SIMPLE_QUOTE_JOB_DRAFT_POSTED]: 'Simple Quote Draft Posted',
  [SMART_SEARCH_VIEW_ALL]: 'smart_search_view_all',
  [TASK_ADDRESS_CHANGED]: 'task_address_changed',
  [TASK_DETAILS_SAVED]: 'task_details_saved',
  [TASK_TEMPLATE_SERVICE_PAGE_VIEWED]: 'Task_Template_Service_Page_Viewed',
  [TASK_TEMPLATE_VIEWED]: 'Task_Template_Viewed',
  [TASKER_ANDROID_CLICKED]: 'Tasker_Android_Clicked',
  [TASKER_CATEGORY_SELECTED]: 'Tasker_Category_Selected',
  [TASKER_DETAIL_VIEWED]: 'Tasker Detail Viewed',
  [TASKER_GET_STARTED_CLICKED]: 'Tasker_Get_Started_Clicked',
  [TASKER_IOS_CLICKED]: 'Tasker_Ios_Clicked',
  [TASKER_METRO_SELECTED]: 'Tasker_Metro_Selected',
  [TASKER_PHOTO_REPORTED]: 'Tasker Photo Reported',
  [TASKER_PHOTO_SECTION_DISPLAYED]: 'Tasker Photo Section Displayed',
  [TASKER_PHOTO_VIEWED]: 'Tasker Photo Viewed',
  [TASKER_PREVIEW_FIND_REPLACEMENT_TAPPED]:
    'taskerPreview_findReplacement_tapped',
  [TASKER_PREVIEW_HIRE_REPLACEMENT_TAPPED]:
    'taskerPreview_hireReplacement_tapped',
  [TASKER_PREVIEW_SEE_PROFILE_TAPPED]: 'taskerPreview_seeProfile_tapped',
  [TASKER_PROFILE_CONTINUE_PURCHASE_FLOW_CLICKED]:
    'tasker_profile_continue_purchase_flow_clicked',
  [TASKER_SCHEDULE_VIEWED]: 'Tasker Schedule Viewed',
  [TASKER_SIGNUP_SCROLL]: 'Tasker_Signup_Scroll',
  [TASKER_SIGNUP_SUCCESS]: 'Tasker Signup Success', // If you change this name change it inside become_a_tasker.haml too
  [TASKER_SIGNUP_VIEWED]: 'Tasker_Signup_Viewed',
  [TASKER_UNAVAILABLE_IN_THE_NEW_ADDRESS]:
    'tasker_unavailable_in_the_new_address',
  [VALUE_PROP_LINK_CLICKED]: 'Value_Prop_Link_Clicked',
  [VISITOR_EXIT]: 'visitor_exit',
};
