import styled from '@taskrabbit/meadow-web/lib/Theme/styled';

// TODO: Complete all responsive design media queries
export const SiteHeader = styled('header')(
  ({
    theme: {
      breakpoints,
      meadow: {
        purpose: { borderRadius, component, primary },
      },
      spacing,
    },
  }) => ({
    '.header__container': {
      [breakpoints.down('lg')]: {
        paddingLeft: '4%',
        paddingRight: '4%',
      },

      alignItems: 'center',
      display: 'flex',
      maxWidth: '1440px',
      paddingLeft: spacing(19),
      paddingRight: spacing(19),
      textAlign: 'center',
      width: '100%',

      [breakpoints.up('md')]: {
        justifyContent: 'space-between',
      },
    },

    '.header__logo': {
      backgroundSize: '147px 25px',
      display: 'inline-block',
      float: 'none',
      height: '25px',
      margin: 'auto',
      position: 'relative',
      verticalAlign: 'bottom',
      width: '147px',

      [breakpoints.up('md')]: {
        backgroundSize: '189px 33px',
        float: 'left',
        height: 'calc(36px * 0.9)',
        margin: '0',
        width: '189px',
      },
    },

    '.header__menu-toggle': {
      color: '#0d7a5f',
      cursor: 'pointer',
      display: 'inline-block',
      float: 'left',
      fontSize: '1.75rem',
      lineHeight: '32px',

      [breakpoints.up('md')]: {
        display: 'none',
      },
    },

    '.header__navigation-container': {
      '.header__navigation-active': {
        color: '#31a7a8',
        fontWeight: 700,
        textDecoration: 'none',
      },

      [breakpoints.up('md')]: {
        display: 'block',
        float: 'right',
      },

      '.header__navigation-link': {
        '&:first-of-type': {
          marginLeft: 0,
        },
        '&:last-child': {
          marginRight: 0,
        },
        color: '#000000',
        display: 'inline-block',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '36px',
        marginLeft: spacing(2.5),
        marginRight: spacing(2.5),

        [breakpoints.up('md')]: {
          i: {
            display: 'none',
          },
        },

        position: 'relative',
        textDecoration: 'none',
      },

      '.header-popover-container': {
        display: 'inline-block',
      },

      '.tasker-signup-btn, .tasker-signup-btn:visited': {
        border: `1px solid ${primary.main}`,
        borderRadius: borderRadius.input,
        color: primary.main,
        display: 'inline-block',
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: '1.4',
        padding: spacing(1, 3.5),
        textDecoration: 'none',
        transitionDuration: '0.16s',
        transitionProperty:
          'background, color, border-color, box-shadow, -webkit-box-shadow',
        transitionTimingFunction: 'ease',
        userSelect: 'none',
      },

      '.tasker-signup-btn:focus, .tasker-signup-btn:hover': {
        border: `1px solid ${primary.emphasis}`,
        color: primary.emphasis,
      },

      display: 'none',
    },

    alignItems: 'center',
    background: component.main,
    borderBottom: `1px solid #dce0e6`,
    display: 'flex',
    height: '70px',
    justifyContent: 'center',
    width: '100%',
  })
);
