// External
import type {
  GetServerSidePropsContext,
  GetStaticPathsContext,
  GetStaticPathsResult,
  GetStaticPropsContext,
} from 'next';
import type { NextRouter } from 'next/router';
import type { ParsedUrlQuery } from 'querystring';

// Internal
import { isProd } from './env';
import { nextBuildLog } from './logger';

export const guardString = (
  value: unknown,
  defaultValue?: string
): string | undefined => (typeof value === 'string' ? value : defaultValue);

export const getNextLocale = (
  context:
    | GetServerSidePropsContext<any>
    | GetStaticPropsContext<any>
    | NextRouter
) => context.locale || context.defaultLocale || 'en-US';

export const parseLocale = (locale?: string, defaultLocale?: string) => {
  if (!locale) locale = defaultLocale;

  const [language, country] = (locale ?? '').split('-');

  return language.length === 2 ? { country, language } : {};
};

export const withStaticPaths =
  (
    path: string,
    gsp: (
      context: GetStaticPathsContext & { locales: string[] }
    ) => Promise<GetStaticPathsResult<ParsedUrlQuery>>
  ) =>
  async (context: GetStaticPathsContext) => {
    let data: GetStaticPathsResult<ParsedUrlQuery> = {
      fallback: 'blocking',
      paths: [],
    };

    let message: string;

    if (process.env.NEXT_DISABLE_GSP === '1') {
      message = '0 paths generated (DISABLED)';
    } else if (!context.locales?.length) {
      message = '0 paths generated (MISSING LOCALES)';
    } else {
      data = await gsp({ ...context, locales: context.locales });

      if (process.env.NEXT_LIMIT_GSP === '1') {
        data.paths = data.paths.slice(0, 1);

        message = `1 path generated (LIMIT)`;
      } else {
        message = `${data.paths.length} path(s) generated`;
      }
    }

    nextBuildLog('gsp', message, undefined, path);

    return data;
  };

export const reportError = (
  error: unknown,
  context?: { [key: string]: any }
) => {
  window.DD_RUM?.addError(error, context);
};

export const getRevalidate = (interval: number) => (!isProd() ? 120 : interval);

export const generateDates = ({
  count,
  startDate: date = new Date(),
}: {
  count: number;
  startDate?: Date;
}) => {
  const dates = [];

  for (let i = 0; i < count; i++) {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + i);
    dates.push(newDate.toLocaleDateString('en-CA'));
  }
  return dates;
};
